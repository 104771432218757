import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { LocaleProvider } from '../context/localeContext';
import Layout from '../components/Layout';
import Section from '../components/Section';
import ResearchPublication from './ResearchPublication';
import NewsletterSignupForm from '../components/NewsletterSignupForm';
import BlogRoll from '../components/BlogRoll';

const CountryStudy = ({ data, pageContext }) => {
  return (
    <LocaleProvider locale={pageContext.locale}>
      <Layout>
        <ResearchPublication
          title={data.countryStudy.frontmatter.title}
          date={data.countryStudy.frontmatter.date}
          heroImage={data.countryStudy.frontmatter.image}
          downloads={data.countryStudy.frontmatter.downloads}
          takeaways={data.countryStudy.frontmatter.takeaways?.map(
            (item) => item.content
          )}
          content={data.countryStudy.htmlAst}
          tags={data.countryStudy.frontmatter.tags}
        />
        {data.relatedPages.edges && !!data.relatedPages.edges.length && (
          <Section overlaySide="left">
            <div className="container py-12">
              <div className="row flex flex-col md:flex-row pb-2 gap-x-36">
                <div className="md:w-1/3 flex flex-wrap content-between text-base">
                  <h2 className="mb-6 md:mb-12">
                    <span className="bg-theme-underline">Related Content</span>
                  </h2>
                </div>
              </div>
              <div className="row">
                <BlogRoll blogPosts={data.relatedPages.edges} />
              </div>
            </div>
          </Section>
        )}
        <Section>
          <NewsletterSignupForm />
        </Section>
      </Layout>
    </LocaleProvider>
  );
};

CountryStudy.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default CountryStudy;

export const pageQuery = graphql`
  query CountryStudyByID($id: String!, $relatedPages: [String]) {
    countryStudy: markdownRemark(id: { eq: $id }) {
      id
      htmlAst
      frontmatter {
        date
        image {
          childImageSharp {
            gatsbyImageData(quality: 90, layout: FULL_WIDTH)
          }
        }
        title
        takeaways {
          content
        }
        downloads {
          path
          label
        }
      }
    }
    relatedPages: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        id: { in: $relatedPages }
        frontmatter: { title: { ne: "" } }
        rawMarkdownBody: { ne: "" }
      }
    ) {
      edges {
        node {
          ...BlogRollItem
        }
      }
    }
  }
`;
